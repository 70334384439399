import React, { useEffect } from 'react';
import Webcam from 'react-webcam';
import { randInt } from 'utils/random';
import { base64ToFile } from '../../utils';

import styles from './styles.module.scss';

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
};

type Props = {
    onPhotoCapture: (photo: File | null) => void;
    onLoad?: () => void;
};

const CapturePhoto: React.FC<Props> = ({ onPhotoCapture, onLoad }) => {
    const webcamRef = React.useRef<Webcam>(null);

    useEffect(() => {
        onLoad?.();
    }, [onLoad]);

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot();
        onPhotoCapture(imageSrc ? base64ToFile(imageSrc, `photo_${randInt(1000, 99999)}.jpg`) : null);
    }, [onPhotoCapture]);

    return (
        <div className={styles.capturePhoto}>
            <p>Okay, take a selfie before moving forward.</p>
            <div className={styles.camera}>
                <Webcam
                    audio={false}
                    height={720}
                    ref={webcamRef}
                    screenshotFormat='image/jpeg'
                    width={1280}
                    videoConstraints={videoConstraints}
                    screenshotQuality={0.8}
                />
            </div>
            <div className={styles.btnBox}>
                <button type='button' onClick={capture}>
                    Capture photo
                </button>
            </div>
        </div>
    );
};

export default CapturePhoto;
