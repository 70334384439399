import React, { lazy, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { TError } from 'types/errors';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
// import { hasVideoCamera } from 'components/common/TwilioRom/utils';
import { DOOR_UNLOCK_TIMEOUT, EndCallTypes } from '../../config';
import { analytics, analyticsEvents, analyticsEventTypes, trackApiError } from '../../analytics';
import { QRRoutes } from '../../routes';
import { requestGetQRTenants, requestStartVideoCall } from '../../services';
import { QRTenant } from '../../types';
import styles from './styles.module.scss';
import ArrowLeftIcon from '../../icons/ArrowLeft';
import SearchIcon from '../../icons/Search';
import { QRContext } from '../../context';
import CameraIcon from '../../icons/Camera';
import CapturePhoto from '../../components/CapturePhoto';
import Loader from '../../components/Loader';
// import { getMediaPermissions } from '../../utils';

const JoinCallRoom = lazy(() => import('../../components/JoinCallRoom'));

const Tenants = () => {
    const { location, setCallDetails } = useContext(QRContext);
    const [tenants, setTenants] = useState<QRTenant[]>([]);
    const [selectedTenant, setSelectedTenant] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCalling, setIsCalling] = useState<boolean>(false);
    const [showCapturePhoto, setShowCapturePhoto] = useState<boolean>(false);
    const [openCallModal, setOpenCallModal] = useState<boolean>(false);
    const [tracks, setTracks] = useState<MediaStreamTrack[]>([]);

    const { push, goBack } = useHistory();

    useEffect(() => {
        const reqTenants = async () => {
            setIsLoading(true);
            try {
                const resp = await requestGetQRTenants();
                setTenants(resp);
            } catch (err) {
                const error = err as TError;
                // setStep(EQRSteps.permissions);
                push(QRRoutes.permissions, { replace: true });
                trackApiError(error, analyticsEvents.callTenant);
            } finally {
                setIsLoading(false);
            }
        };

        reqTenants();
    }, [push]);

    useEffect(() => {
        analytics?.page(analyticsEvents.callTenant, {
            event_type: analyticsEventTypes.pageLoad,
        });
    }, []);

    const handleSelectTenant = useCallback(
        (id: string) => () => {
            setSelectedTenant(id);
        },
        [],
    );

    const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    }, []);

    const reqVideoCall = useCallback(
        async (photo: File | null) => {
            setShowCapturePhoto(false);
            setIsCalling(true);

            // if (!location) {
            //     toast.error('Location permissions not found, please enable location permissions and try again.');
            //     return;
            // }

            // if (!photo) {
            //     analytics?.track(analyticsEvents.callTenant, {
            //         event_type: analyticsEventTypes.failedCaptureUserPhoto,
            //     });
            //     toast.error('Failed to capture photo');
            //     return;
            // }

            analytics?.track(analyticsEvents.callTenant, {
                event_type: analyticsEventTypes.userPhotoCaptured,
            });

            try {
                // const hasMediaPermissions = await hasVideoCamera();
                // if (!hasMediaPermissions) await getMediaPermissions();
                // await getMediaPermissions();
                const stream = await navigator.mediaDevices?.getUserMedia({ video: { facingMode: 'user' }, audio: true });
                setTracks(stream.getTracks());
                analytics?.track(analyticsEvents.callTenant, {
                    event_type: analyticsEventTypes.callInitialized,
                });
                // const tenantName = tenants.find(tenant => tenant.id_str === selectedTenant)?.name;
                const data = await requestStartVideoCall({
                    call_type: 'TENANT',
                    tenant_id: selectedTenant,
                    longitude: location?.longitude,
                    latitude: location?.latitude,
                    files: photo ? [photo] : undefined,
                });
                if (data.msg) {
                    toast.error(data.msg);
                } else {
                    setCallDetails(data);
                    // setRoomLabel(tenantName || '');
                    // setIsFrontDest(false);
                    setOpenCallModal(true);
                }
            } catch (err) {
                const error = err as TError;
                toast.error(error?.message);
                trackApiError(error, analyticsEvents.callTenant);
            } finally {
                setIsCalling(false);
            }
        },
        [location, selectedTenant, setCallDetails],
    );

    // const toggleCapturePhoto = useCallback(() => {
    //     setShowCapturePhoto(prev => !prev);
    // }, []);

    const onCallCallTenantPress = useCallback(() => {
        analytics?.track(analyticsEvents.callTenant, {
            event_type: analyticsEventTypes.callTenantPressed,
        });
        reqVideoCall(null);
        // toggleCapturePhoto();
    }, [reqVideoCall]);

    const onCapturePhotoLoad = useCallback(() => {
        analytics?.track(analyticsEvents.callTenant, {
            event_type: analyticsEventTypes.captureSelfieScreenLoaded,
        });
    }, []);

    const onAnswerCall = useCallback(() => {
        analytics?.track(analyticsEvents.callTenant, {
            event_type: analyticsEventTypes.callAnswered,
        });
    }, []);

    const onEndCall = useCallback(
        (type: EndCallTypes) => {
            setOpenCallModal(false);
            analytics?.track(analyticsEvents.callTenant, {
                event_type: type,
            });
            setTracks([]);
        },
        [setOpenCallModal],
    );

    const onDoorUnlock = useCallback(() => {
        analytics?.track(analyticsEvents.callTenant, {
            event_type: analyticsEventTypes.doorUnlocked,
        });
        setTimeout(() => {
            goBack();
        }, DOOR_UNLOCK_TIMEOUT);
    }, [goBack]);

    const onDoorUnlockFailed = useCallback(() => {
        analytics?.track(analyticsEvents.callTenant, {
            event_type: analyticsEventTypes.doorUnlockFailed,
        });
    }, []);

    const filteredTenants = tenants.filter(tenant => tenant.name.toLowerCase().includes(search.toLowerCase()));
    const roomName = useMemo(() => tenants.find(tenant => tenant.id_str === selectedTenant)?.name || '', [tenants, selectedTenant]);

    return (
        <div className={styles.root}>
            <div className={styles.searchBar}>
                <div className={styles.backBox}>
                    <button type='button' onClick={goBack}>
                        <ArrowLeftIcon />
                    </button>
                </div>
                <div className={styles.field}>
                    <input type='text' placeholder='SEARCH' onChange={handleSearch} />
                    <span>
                        <SearchIcon />
                    </span>
                </div>
            </div>
            {isLoading && (
                <div className={styles.loading}>
                    <Loader size={62} />
                </div>
            )}
            {filteredTenants?.length > 0 && (
                <ul className={styles.list}>
                    {filteredTenants.map(tenant => (
                        <li key={tenant.id_str} className={cn({ [styles.active]: selectedTenant === tenant.id_str })}>
                            <button
                                className={styles.itemBtn}
                                type='button'
                                disabled={isCalling}
                                onClick={handleSelectTenant(tenant.id_str)}
                            >
                                {tenant.name}
                            </button>
                            <button className={styles.callBtn} type='button' onClick={onCallCallTenantPress} disabled={isCalling}>
                                CALL
                                {isCalling ? <Loader size={18} /> : <CameraIcon />}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
            {showCapturePhoto && <CapturePhoto onPhotoCapture={reqVideoCall} onLoad={onCapturePhotoLoad} />}
            {openCallModal && (
                <JoinCallRoom
                    roomLabel={roomName}
                    isFrontDesc={false}
                    tracks={tracks}
                    onAnswerCall={onAnswerCall}
                    onEndCall={onEndCall}
                    onDoorUnlock={onDoorUnlock}
                    onDoorUnlockFailed={onDoorUnlockFailed}
                />
            )}
        </div>
    );
};

export default Tenants;
