import { AnalyticsBrowser } from '@segment/analytics-next';
import { TError } from 'types/errors';

const key = process.env.REACT_APP_SEGMENT_KEY || '';

export const analytics = AnalyticsBrowser.load({ writeKey: key });
export const analyticsUserId = 'qr-app-user-1234';

export const analyticsEvents = {
    initQrDetails: 'qr_app_init_qr_details',
    permissions: 'qr_app_permissions',
    options: 'qr_app_options',
    callTenant: 'qr_app_call_tenant',
    pinAccess: 'qr_app_pin_access',
    packageScan: 'qr_app_package_scan',
};

export const analyticsEventTypes = {
    pageLoad: 'page_load',
    packageScansUploaded: 'package_scans_uploaded',
    uploadPackageScanInitialized: 'upload_package_scan_initialized',
    uploadPackageScanCompleted: 'upload_package_scan_completed',
    uploadPackageScanFailed: 'upload_package_scan_failed',
    packagePhotoCaptured: 'package_photo_is_captured',
    failedCapturePackagePhoto: 'failed_capture_package_photo',
    scanPackageDoneButtonPressed: 'scan_package_done_button_pressed',
    scanPackageUploadScreenShown: 'scan_package_upload_screen_shown',
    uploadPackagesDoneButtonPressed: 'upload_packages_done_button_pressed',
    failedCaptureUserPhoto: 'failed_capture_user_photo',
    pinError: 'pin_error',
    submitPinButtonPressed: 'submit_pin_button_pressed',
    userPhotoCaptured: 'user_photo_is_captured',
    captureSelfieScreenLoaded: 'capture_selfie_screen_loaded',
    doorUnlocked: 'door_unlocked',
    doorUnlockFailed: 'door_unlock_failed',
    callInitialized: 'call_initialized',
    callAnswered: 'call_answered',
    apiError: 'api_error',
    callTenantPressed: 'call_tenant_pressed',
    optionsInfo: 'options_info',
    secondaryFrontDescCallInitialized: 'secondary_front_desk_call_initialized',
    callFrontDeskPressed: 'call_front_desk_pressed',
    requestPermissionsButtonClicked: 'request_permissions_button_clicked',
    videoMicPermissionsRequested: 'video_mic_permissions_requested',
    videoMicDevicesNotFound: 'video_mic_devices_not_found',
    mediaPermissionsDeniedByUser: 'media_permissions_denied_by_user',
    failedGetVideoMicPermissions: 'failed_to_get_video_mic_permissions',
    locationPermissionsRequested: 'location_permissions_requested',
    failedRequestLocationPermissions: 'failed_request_location_permissions',
};

export const trackApiError = (err: TError, event: string) => {
    if (err.config && err.response) {
        analytics?.track(event, {
            event_type: analyticsEventTypes.apiError,
            data: err.response.data?.data ? JSON.stringify(err.response.data?.data) : undefined,
            status: err.response.status,
            url: err.config.url,
            method: err.config.method,
        });
    }
};
