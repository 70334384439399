import React, { FC, memo, useRef, useCallback, useEffect } from 'react';
import { analyticsIntercomEventTypes, dashboardAnalytics, dashboardAnalyticsEvents } from 'hooks/useAnalytics/config';
import { ICustomMap } from 'types/ICustomMap';

interface IProps {
    audio: string;
}

const Sound: FC<IProps> = ({ audio }) => {
    useEffect(() => {
        if (audio) {
            const audioInstance = new Audio(audio);
            const playPromise = audioInstance.play();
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.playingSound,
            });
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {})
                    .catch(error => {
                        window?.console.info(error);
                        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                            event_type: analyticsIntercomEventTypes.playSoundError,
                            message: error?.message,
                        });
                    });
            }
        }
    }, [audio]);

    return <></>;
};

export default memo(Sound);

export const useAudio = (src: string) => {
    const audioRef = useRef<ICustomMap | null>(null);

    useEffect(() => {
        let audio: HTMLAudioElement | null = null;

        if (src) {
            audio = new Audio(src);
            audioRef.current = audio;
        }
        return () => {
            audioRef.current = null;
        };
    }, [src]);

    const play = useCallback(() => {
        if (audioRef.current) {
            audioRef.current
                ?.play()
                .then(() => {
                    dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                        event_type: analyticsIntercomEventTypes.playingSound,
                    });
                })
                .catch((error: ICustomMap) => {
                    window?.console.error('Play error:', error);
                    dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                        event_type: analyticsIntercomEventTypes.playSoundError,
                    });
                });
        }
    }, []);

    const pause = useCallback(() => {
        if (audioRef.current) {
            audioRef.current?.pause();
            audioRef.current.currentTime = 0;
        }
    }, []);

    return { play, pause };
};
