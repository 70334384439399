export const END_CALL_TIMEOUT = 45000;
export const FALLBACK_CALL_TIMEOUT = 35000; // should be less than END_CALL_TIMEOUT
export const DOOR_UNLOCK_TIMEOUT = 4000;

export enum EndCallTypes {
    callTimeout = 'end_call_timeout',
    doorUnlock = 'door_unlocked',
    doorUnlockFailed = 'door_unlock_failed',
    endByReceiver = 'call_ended_by_receiver',
    endByVisitor = 'call_ended_by_visitor',
}
