import { AnalyticsBrowser } from '@segment/analytics-next';

const segmentKey = process.env.REACT_APP_SEGMENT_KEY || '';
export const dashboardAnalytics = AnalyticsBrowser.load({ writeKey: segmentKey });

export const analyticsDashboardUserId = 'dashboard-app-user-1234';

export const dashboardAnalyticsEvents = {
    intercom: 'dashboard_app_intercom',
};

export const analyticsIntercomEventTypes = {
    pushMessagesPermissionsRequested: 'push_messages_permissions_requested',
    pushMessagesPermissionsDenied: 'push_messages_permissions_denied',
    pushMessagesPermissionsDeniedByUser: 'push_messages_permissions_denied_by_user',
    pushMessagesPermissionsGranted: 'push_messages_permissions_granted',
    pushMessagesPermissionsAlreadyGranted: 'push_messages_permissions_already_granted',
    pushMessagesNotSupported: 'push_messages_not_supported',
    userMediaPermissionsRequested: 'user_media_permissions_requested',
    userMediaPermissionsGranted: 'user_media_permissions_granted',
    userMediaPermissionsError: 'user_media_permissions_error',
    userMediaPermissionsAlreadyGranted: 'user_media_permissions_already_granted',
    startCallPushMessageReceived: 'start_call_push_message_received',
    endCallPushMessageReceived: 'end_call_push_message_received',
    playingSound: 'playing_sound',
    playSoundError: 'play_sound_error',
    callModalOpened: 'call_modal_opened',
    answerCallButtonPressed: 'answer_call_button_pressed',
    requestAnswerCall: 'request_answer_call',
    failedAnswerCall: 'failed_answer_call',
    callAnswered: 'call_answered',
    callEnded: 'call_ended',
    requestDoorUnlock: 'request_door_unlock',
    doorUnlocked: 'door_unlocked',
};
